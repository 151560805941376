(function () {
	'use strict';
	angular
		.module('app')
		.factory('manageConfig', [
			'$sce',
			'$http',
			'seedcodeCalendar',
			'utilities',
			'hash',
			'dataStore',
			'daybackIO',
			'firebaseIO',
			'manageUser',
			'manageEventSources',
			'manageSettings',
			manageConfig,
		]);

	function manageConfig(
		$sce,
		$http,
		seedcodeCalendar,
		utilities,
		hash,
		dataStore,
		daybackIO,
		firebaseIO,
		manageUser,
		manageEventSources,
		manageSettings
	) {
		var saveDebounce;

		return {
			createUserToken: createUserToken,
			getUserData: getUserData,
			getGroupSettings: getGroupSettings,
			getSettingsOptions: getSettingsOptions,
			applyLocalSetting: applyLocalSetting,
			userDataMap: userDataMap,
			groupDataMap: groupDataMap,
			groupMemberDataMap: groupMemberDataMap,
			calendarActionDataMap: calendarActionDataMap,
			customFieldDataMap: customFieldDataMap,
			customActionDataMap: customActionDataMap,
			contactObjectsDataMap: contactObjectsDataMap,
			projectObjectsDataMap: projectObjectsDataMap,
			resourceObjectsDataMap: resourceObjectsDataMap,
			updateSetting: updateSetting,
			updateConfig: updateConfig,
			getSourcePosition: getSourcePosition,
			addSource: addSource,
			deleteSource: deleteSource,
			updateSource: updateSource,
			getObjectPosition: getObjectPosition,
			addObject: addObject,
			updateObject: updateObject,
			deleteObject: deleteObject,
			transformObjects: transformObjects,
			transformObjectData: transformObjectData,
			transformSettings: transformSettings,
			transformSources: transformSources,
			transformSourceData: transformSourceData,
			transformSource: transformSource,
			adjustUserLicenseCount: adjustUserLicenseCount,
			memberConflictCheck: memberConflictCheck,
			getActiveMemberCount: getActiveMemberCount,
			removeMemberUser: removeMemberUser,
			defaultResourceChanged: defaultResourceChanged,
			updateSourceObject: updateSourceObject,
			getFieldMapItem: getFieldMapItem,
		};

		function userDataMap() {
			return {
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				userID: {
					setting: 'userID',
					visible: false,
					defaultValue: '',
				},
				account: {
					setting: 'account',
					displayValue: 'Account Email Address',
					placeholder: "Enter member's email address for login",
					visible: true,
					defaultValue: '',
				},
				firstName: {
					setting: 'firstName',
					displayValue: 'First Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				lastName: {
					setting: 'lastName',
					displayValue: 'Last Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				admin: {
					setting: 'admin',
					displayValue: 'Admin Access',
					placeholder: '',
					format: 'yesno',
					visible: true,
					defaultValue: false,
				},
				readOnly: {
					setting: 'readOnly',
					displayValue: 'Read Only',
					placeholder: '',
					format: 'yesno',
					visible: true,
					defaultValue: false,
				},
			};
		}

		function groupDataMap() {
			return {
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				name: {
					setting: 'name',
					displayValue: 'Group Name',
					placeholder: 'Group Name',
					visible: true,
					defaultValue: '',
				},
			};
		}

		function contactObjectsDataMap(type) {
			return {
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				object: {
					setting: 'object',
					displayValue: 'Object Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				objectDisplay: {
					setting: 'objectDisplay',
					displayValue: 'Display Object As',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				searchField: {
					setting: 'searchField',
					displayValue: 'Search Field',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				displayField: {
					setting: 'displayField',
					displayValue: 'display Field',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
			};
		}

		function projectObjectsDataMap(type) {
			return {
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				object: {
					setting: 'object',
					displayValue: 'Object Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				objectDisplay: {
					setting: 'objectDisplay',
					displayValue: 'Display Object As',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				searchField: {
					setting: 'searchField',
					displayValue: 'Search Field',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				displayField: {
					setting: 'displayField',
					displayValue: 'display Field',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
			};
		}

		function resourceObjectsDataMap(type) {
			return {
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				object: {
					setting: 'object',
					displayValue: 'Object Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				objectDisplay: {
					setting: 'objectDisplay',
					displayValue: 'Display Object As',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				searchField: {
					setting: 'searchField',
					displayValue: 'Search Field',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				displayField: {
					setting: 'displayField',
					displayValue: 'display Field',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
			};
		}

		function customFieldDataMap(type) {
			return {
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				formatas: {
					setting: 'formatas',
					displayValue: 'Format',
					placeholder: '',
					visible: true,
					defaultValue: type || '',
				},
				name: {
					setting: 'name',
					displayValue: 'Label',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				field: {
					setting: 'field',
					displayValue: 'Mapped to Field',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				list: {
					setting: 'list',
					displayValue: 'List Items',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				delimiter: {
					setting: 'delimiter',
					displayValue: 'List Delimiter',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				dateFormat: {
					setting: 'dateFormat',
					displayValue: 'Date Format',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
			};
		}

		function calendarActionDataMap(type) {
			return {
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				type: {
					setting: 'type',
					displayValue: 'Action Type',
					placeholder: '',
					visible: true,
					defaultValue: type || '',
				},
				name: {
					setting: 'name',
					displayValue: 'Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				actionCode: {
					setting: 'actionCode',
					displayValue: 'Action Code',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				preventDefault: {
					setting: 'preventDefault',
					displayValue: 'Prevent Default Action',
					placeholder: '',
					visible: true,
					defaultValue: false,
				},
				actionEnabledFor: {
					setting: 'actionEnabledFor',
					displayValue: 'Action Enabled For',
					placeholder: '',
					visible: true,
					defaultValue: {
						app: true,
						shares: true,
					},
				},
			};
		}

		function customActionDataMap(type) {
			return {
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				type: {
					setting: 'type',
					displayValue: 'Action Type',
					placeholder: '',
					visible: true,
					defaultValue: type || '',
				},
				name: {
					setting: 'name',
					displayValue: 'Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				url: {
					setting: 'url',
					displayValue: 'Action URL',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				newWindow: {
					setting: 'newWindow',
					displayValue: 'New Window',
					placeholder: '',
					visible: true,
					defaultValue: false,
				},
				preventDefault: {
					setting: 'preventDefault',
					displayValue: 'Prevent Default Action',
					placeholder: '',
					visible: true,
					defaultValue: false,
				},
				eventType: {
					setting: 'eventType',
					displayValue: 'For Events That Are',
					placeholder: '',
					visible: true,
					defaultValue: {
						editable: true,
					},
				},
			};
		}

		function groupMemberDataMap() {
			return {
				active: {
					setting: 'active',
					displayValue: 'Active',
					placeholder: '',
					format: 'yesno',
					visible: false,
					defaultValue: true,
				},
				id: {
					setting: 'id',
					visible: false,
					defaultValue: utilities.generateUID(),
				},
				userID: {
					setting: 'userID',
					visible: false,
					defaultValue: '',
				},
				account: {
					setting: 'account',
					displayValue: 'Account Email Address',
					placeholder: "Enter member's email address for login",
					visible: true,
					defaultValue: '',
				},
				firstName: {
					setting: 'firstName',
					displayValue: 'First Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				lastName: {
					setting: 'lastName',
					displayValue: 'Last Name',
					placeholder: '',
					visible: true,
					defaultValue: '',
				},
				admin: {
					setting: 'admin',
					displayValue: 'Admin Access',
					placeholder: '',
					format: 'yesno',
					visible: true,
					defaultValue: false,
				},
				manageFilters: {
					setting: 'manageFilters',
					displayValue: 'Change Statuses and Resources',
					placeholder: '',
					format: 'yesno',
					visible: true,
					defaultValue: false,
				},
				readOnly: {
					setting: 'readOnly',
					displayValue: 'Read Only',
					placeholder: '',
					format: 'yesno',
					visible: true,
					defaultValue: false,
				},
				allowSharing: {
					setting: 'allowSharing',
					displayValue: 'Allow Sharing',
					placeholder: '',
					format: 'yesno',
					visible: true,
					defaultValue: true,
				},
				userGroupAuthSources: {
					setting: 'userGroupAuthSources',
					displayValue: 'Do not require login',
					placeholder: '',
					format: 'toggle',
					visible: false,
					defaultValue: '',
				},
			};
		}

		function createUserToken(email, uid, idToken) {
			return manageUser.createUserToken(email, uid, idToken);
		}

		function getGroupSettings(callback) {
			firebaseIO.getGroupData('group', onSuccess);

			function onSuccess(result) {
				if (callback) {
					callback(result);
				}
			}
		}

		function getUserData() {
			return daybackIO.getUser();
		}

		function updateSetting(property, value) {
			var settings = seedcodeCalendar.get('settings');
			var output = {};

			settings[property] = value;
			output[property] = value;
			firebaseIO.setGroupData('', 'settings', output, true);

			updateConfig(property);
			return output;
		}

		function getSettingsOptions(settingsItem) {
			var configMap = seedcodeCalendar.calendar.configMap;
			var setting = configMap[settingsItem];
			var options = setting.options ? setting.options : [];
			return options;
		}

		function applyLocalSetting(setting, preventFetching, preventInit) {
			var config = seedcodeCalendar.get('config');
			var settingValue;
			if (!preventInit) {
				var eventBackup = seedcodeCalendar
					.get('element')
					.fullCalendar('clientEvents');
			}

			var configItem = seedcodeCalendar.calendar.configMap[setting];
			var isViewSetting;
			//Add to config so filemaker client can read fetching state - We can't use this right now as this creates a state in DBKFM where events aren't fetch on startup
			// config.preventFetching = preventFetching;

			if (configItem.views && Array.isArray(configItem.views)) {
				for (var i = 0; i < configItem.views.length; i++) {
					if (configItem.views[i] === config.defaultView) {
						//Make sure the view setting exists
						if (!config.viewSettings) {
							config.viewSettings = {};
						}
						if (!config.viewSettings[config.defaultView]) {
							config.viewSettings[config.defaultView] = {};
						}
						//Set the property in the proper view setting
						config.viewSettings[config.defaultView][setting] =
							config[setting];
						isViewSetting = true;
						break;
					}
				}
			}

			//Save this state if it is set to
			if (configItem.saved) {
				if (isViewSetting) {
					dataStore.saveState(
						config.defaultView,
						JSON.stringify(config.viewSettings[config.defaultView])
					);
				} else {
					settingValue =
						configItem.allowEmpty && config[setting] === ''
							? '~empty~'
							: config[setting];

					if (
						typeof settingValue === 'object' &&
						settingValue !== null
					) {
						settingValue = JSON.stringify(settingValue);
					}
					dataStore.saveState(configItem.saved, settingValue);
				}
			}
			if (!preventInit) {
				seedcodeCalendar.init('initCalendar', {
					eventBackup: eventBackup,
				});
			}
		}

		function updateConfig(settingName) {
			var configMap = seedcodeCalendar.calendar.configMap;
			var config = seedcodeCalendar.get('config');
			var settings = seedcodeCalendar.get('settings');
			var configItem = getConfigMapItem(settingName);

			//Apply setting to config
			manageSettings.applyConfigItem(
				config,
				settings,
				configMap[configItem],
				configItem,
				true
			);
		}

		function getSourcePosition(sources, source) {
			var sourceID = source.parentID ? source.parentID : source.id;
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].id === sourceID) {
					return i;
				}
			}
		}

		function addSource(sourceType, localParent, sourceData) {
			var sources = seedcodeCalendar.get('sources');

			var source = {};
			var output = {};
			//var sourcePosition = sources.length;
			//Add our defaults
			source.sourceTypeID = sourceType;
			source.id = sourceType + '-' + utilities.generateUID();

			if (sourceData) {
				source = {...sourceData, ...source};
			} else {
				//Initialize source data
				source.fieldMap = {};
				source.backgroundColor = utilities.generateRandomColor();
				source = transformSourceData(source);
			}

			//Set if parent source for separate schedules
			if (localParent) {
				source.localParent = true;
			}

			//Add new source to source list
			sources.push(source);

			// output[sourcePosition] = source;
			output[source.id] = JSON.parse(JSON.stringify(source));
			firebaseIO.setGroupData('', 'sources', output, true);
			//Make sure to set our source type and any other data not stored in settings
			source.sourceTypeID = sourceType;

			return source;
		}

		function deleteSource(source) {
			var sources = seedcodeCalendar.get('sources');
			var output = {};

			var sourcePosition = getSourcePosition(sources, source);

			//Remove from our local array
			sources.splice(sourcePosition, 1);

			//Create firebase output
			// output[sourcePosition] = null;
			output[source.id] = null;
			//Write all sources back to firebase
			firebaseIO.setGroupData('', 'sources', output, true);

			return source;
		}

		function updateSource(
			source,
			itemID,
			property,
			value,
			type,
			viewedSources,
			callback
		) {
			var sources = seedcodeCalendar.get('sources');
			var schedule = source.schedule ? source.schedule : source;
			var sourcePosition = getSourcePosition(sources, source);
			var rootSourceData;
			var path;
			var child;
			var output = {};
			var basePath = source.isSchedule
				? 'sources/' + source.parentID + '/' + source.id
				: 'sources/' + source.id;
			// Set any empty string or false value to null so they are removed from firebase
			if (!value) {
				value = null;
			}

			if (type === 'fieldMap') {
				if (!source.fieldMap) {
					source.fieldMap = {};
				}
				source.fieldMap[property] = value;
				path = basePath;
				child = 'fieldMap';
			} else if (type === 'labelMap') {
				if (!source.labelMap) {
					source.labelMap = {};
				}
				source.labelMap[property] = value;
				path = basePath;
				child = 'labelMap';
			} else if (type === 'unusedMap') {
				if (!source.unusedMap) {
					source.unusedMap = {};
				}
				source.unusedMap[property] = value;
				path = basePath;
				child = 'unusedMap';
			} else if (type === 'allowHTMLMap') {
				if (!source.allowHTMLMap) {
					source.allowHTMLMap = {};
				}
				source.allowHTMLMap[property] = value;
				path = basePath;
				child = 'allowHTMLMap';
			} else if (type === 'hiddenFieldMap') {
				if (!source.hiddenFieldMap) {
					source.hiddenFieldMap = {};
				}
				source.hiddenFieldMap[property] = value;
				path = basePath;
				child = 'hiddenFieldMap';
			} else if (type === 'readOnlyFieldMap') {
				if (!source.readOnlyFieldMap) {
					source.readOnlyFieldMap = {};
				}
				source.readOnlyFieldMap[property] = value;
				path = basePath;
				child = 'readOnlyFieldMap';
			} else if (type === 'allowTextFieldMap') {
				if (!source.allowTextFieldMap) {
					source.allowTextFieldMap = {};
				}
				source.allowTextFieldMap[property] = value;
				path = basePath;
				child = 'allowTextFieldMap';
			} else if (type === 'relatedValueMap') {
				if (!source.relatedValueMap) {
					source.relatedValueMap = {};
				}
				source.relatedValueMap[property] = value;
				path = basePath;
				child = 'relatedValueMap';
			} else if (type === 'criteria') {
				if (!source[type]) {
					source[type] = {};
				}
				source[type][property] = value;
				path = basePath + '/' + type;
				child = itemID;
			} else if (type === 'customFields') {
				source.customFields[itemID][property] = value;
				path = basePath + '/customFields';
				child = itemID;
			} else if (type === 'customActionEventType') {
				source.customActions[itemID]['eventType'][property] = value;
				path = basePath + '/customActions/' + itemID;
				child = 'eventType';
			} else if (type === 'eventActionEventType') {
				source.eventActions[itemID]['eventType'][property] = value;
				path = basePath + '/eventActions/' + itemID;
				child = 'eventType';
			} else if (type === 'customActions') {
				source.customActions[itemID][property] = value;
				path = basePath + '/customActions';
				child = itemID;
			} else if (type === 'eventActions') {
				source.eventActions[itemID][property] = value;
				path = basePath + '/eventActions';
				child = itemID;
			} else if (type === 'contactObjects') {
				source.contactObjects[itemID][property] = value;
				path = basePath + '/contactObjects';
				child = itemID;
			} else if (type === 'projectObjects') {
				source.projectObjects[itemID][property] = value;
				path = basePath + '/projectObjects';
				child = itemID;
			} else if (type === 'resourceObjects') {
				source.resourceObjects[itemID][property] = value;
				path = basePath + '/resourceObjects';
				child = itemID;
			} else if (type === 'contactData') {
				if (!source.contactData) {
					source.contactData = {};
				}
				source.contactData[property] = value;
				path = basePath;
				child = 'contactData';
			} else if (type === 'projectData') {
				if (!source.projectData) {
					source.projectData = {};
				}
				source.projectData[property] = value;
				path = basePath;
				child = 'projectData';
			} else if (type === 'resourceData') {
				if (!source.resourceData) {
					source.resourceData = {};
				}
				source.resourceData[property] = value;
				path = basePath;
				child = 'resourceData';
			} else {
				rootSourceData = true;
				source[property] = value;
				if (source.isSchedule) {
					if (!sources[sourcePosition][source.id]) {
						sources[sourcePosition][source.id] = {};
					}
					sources[sourcePosition][source.id][property] = value;
					path = 'sources/' + source.parentID;
					child = source.id;
				} else {
					path = 'sources';
					child = source.id;
				}
			}

			if (rootSourceData && property === 'backgroundColor') {
				source.sourceTemplate.changeScheduleColor(
					source.backgroundColor,
					settingUpdateComplete,
					schedule
				);
			} else if (rootSourceData && property === 'name') {
				clearTimeout(saveDebounce);
				saveDebounce = window.setTimeout(function () {
					source.sourceTemplate.changeScheduleName(
						source.name,
						settingUpdateComplete,
						schedule
					);
				}, 500);
			} else if (
				rootSourceData &&
				(property === 'isPrimary' ||
					property === 'primaryCalendarOverride')
			) {
				primaryChanged(source, viewedSources, settingUpdateComplete);

				//Update the source property so radio button reflects the false value
				if (!value) {
					source[property] = false;
				}
			} else {
				output[property] = value;
				firebaseIO.setGroupData(path, child, output, true);
			}

			function settingUpdateComplete(result) {
				var message;
				//Run a check for error and revert if there is an error
				if (result.error) {
					//Run revert process
					if (result.message) {
						message = result.message;
					} else if (result.error && result.error.message) {
						message = result.error.message;
					} else {
						message = 'Error updating data. Please try again';
					}

					utilities.showMessage(message, 0, 8000, 'error');
				}
				if (callback) {
					callback();
				}
			}

			return sources[sourcePosition];
		}

		function defaultResourceChanged(source, callback) {
			var defaultResourceData = {};
			var key = utilities.stringToID(source.id);
			defaultResourceData[key] = source.defaultResource;
			manageSettings.setGroupSettings(
				'defaultResources',
				defaultResourceData,
				true,
				function () {
					if (callback) {
						callback();
					}
				}
			);
		}

		function primaryChanged(source, sources, callback) {
			var config = seedcodeCalendar.get('config');
			var scheduleKey = source.schedule ? source.schedule.id : source.id;
			var primaryCalendar = source.isPrimary ? scheduleKey : null;
			var primaryCalendarOverride;

			manageSettings.setUserSettings(
				null,
				{primaryCalendar: primaryCalendar},
				null
			);

			if (config.admin) {
				// Get override data if any
				primaryCalendarOverride = source.primaryCalendarOverride
					? scheduleKey
					: null;
				config.primaryCalendarOverride = primaryCalendarOverride;

				// Set primary override data
				manageSettings.setGroupSettings(
					'primaryCalendarOverride',
					primaryCalendarOverride,
					false,
					null
				);
			}
			//Set config value
			config.primaryCalendar = scheduleKey;
			primaryCalendar = primaryCalendarOverride || primaryCalendar;

			//Reset schedule primary selection
			for (var i = 0; i < sources.length; i++) {
				if (primaryCalendar && sources[i].id !== source.id) {
					sources[i].isPrimary = false;
					sources[i].primaryCalendarOverride = false;
				}
			}
		}

		function getObjectPosition(objects, id) {
			for (var i = 0; i < objects.length; i++) {
				if (objects[i].id === id) {
					return i;
				}
			}
		}

		function adjustUserLicenseCount(count, callback) {
			var config = seedcodeCalendar.get('config');
			var hashData = hash.get();
			var settings = seedcodeCalendar.get('settings');

			var groupID = config.groupID;
			var subscriptionID = hashData.subscriptionID;
			var productHandle = hashData.productName;
			var isTrial = !hashData.isSubscription;

			if (!hashData.hasActivationObject || count === hashData.userLimit) {
				// No change needed
				callback(settings.activation);
				return;
			}

			var template =
				'<div style="background: rgba(0,0,0,0.75); color: white;">' +
				//add a little margin to the top of the buttons (repeating-dialog), if there's three in case they stack (especially in french, etc.)
				'<div class="pad-large text-center">' +
				'<h4 translate>' +
				'Updating User License Count' +
				'</h4>' +
				'<p translate>' +
				'One moment...' +
				'</p>' +
				//'<div class="pad">' + (secondaryButtonText ? cancelButton + '&nbsp;&nbsp;' + confirmButton + ' ' + secondaryButton : cancelButton + ' ' + confirmButton  ) +
				//'</div>' +
				'</div>' +
				'</div>';

			var config = {
				container: document.querySelector('#calendar-container')
					? '#calendar-container'
					: '#app-container',
				type: 'modal', // modal or popover
				destroy: true,
				confirmFunction: '',
				cancelFunction: '',
				secondaryFunction: '',
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(config, template);

			daybackIO.updateAllowedUserCount(
				groupID,
				subscriptionID,
				productHandle,
				count,
				isTrial,
				userCountSuccess
			);

			function userCountSuccess(result) {
				config.show = false;

				if (!result) {
					utilities.showModal(
						'Error',
						'There was a problem updating the user license count. Please try again later.',
						null,
						null,
						'ok',
						null
					);
				}
				if (callback) {
					callback(result);
				}
			}
		}

		function memberConflictCheck(member, group, members, callback) {
			const currentUser = daybackIO.getUser();
			const groupID = currentUser.group.id;
			const platform = utilities.getDBKPlatform();
			daybackIO.findUser('account', member.account, userFound);

			function userFound(result) {
				let user;
				if (result) {
					//extract user data from result. This is wrapped in the user id
					for (let property in result) {
						if (property.substring(0, 3) !== 'sf-') {
							// If a match is found that is not a salesforce user then use that account data
							user = result[property].user;
							break;
						}
					}

					//does this user already belong to this group?
					for (let i = 0; i < members.length; i++) {
						if (
							user?.email === members[i].account &&
							members[i].userID
						) {
							//user is already added and active in this group
							resultCallback(false);
							return;
						}
					}

					// If we ended up with no valid user (most likely because they are a salesforce user) then add them as a new member and create new account
					if (!user) {
						//No user exists with this email so create it
						licenseIncreasedAddUser(
							member,
							group,
							members,
							assignUser
						);
					} else {
						if (platform === 'dbkfmjs' || platform === 'dbkfmwd') {
							// In FileMaker we don't want to allow multiple groups
							//Account exists but not a member of the group.
							resultCallback(false);
							return;
						}

						// User already exists so we need to add them to this group
						//get this users primary group info
						//if needed we need to add a group name for their primary group
						var fullPath = 'groups/' + user.group.id;
						firebaseIO.getData(fullPath, function (result) {
							processUserGroup(
								result,
								user,
								group,
								members,
								member
							);
						});
					}
				} else {
					//No user exists with this email so create it
					licenseIncreasedAddUser(member, group, members, assignUser);
				}

				function processUserGroup(
					result,
					user,
					group,
					members,
					member
				) {
					// User already exists they are just being added to the group
					if (result) {
						//set the group name for the user's primary group
						//at the user level if not already there
						let primaryGroupName;
						if (result?.group?.name) {
							primaryGroupName = result.group.name;
						} else if (result && result.members) {
							//Group Name should always be there after backfill
							//but we'll leave this routine as a fallback jic
							const primaryGroupPrimaryMember = getPrimaryMember(
								result.members,
								result
							);
							const groupNumber = 1;
							const dmn = primaryGroupPrimaryMember
								? primaryGroupPrimaryMember.split('@')[1]
								: 'Group';
							primaryGroupName = dmn + '-' + groupNumber;
						}
						if (!user.group.name) {
							user.group.name = primaryGroupName;
						}

						if (!user.additionalGroups) {
							user.additionalGroups = {};
						}
						//identify the "primary" member for group name
						const primaryMember = getPrimaryMember(members, group);
						//use domain name for initial group name
						const groupNumber =
							Object.keys(user.additionalGroups).length + 2;
						const dmn = primaryMember
							? primaryMember.split('@')[1]
							: 'Group';

						// Create additional group
						const additionalGroup = {};
						additionalGroup.id = group.id;
						additionalGroup.memberID = member.id;
						const additionalGroupName = group.name
							? group.name
							: dmn + '-' + groupNumber;
						additionalGroup.groupName = additionalGroupName;
						user.additionalGroups[additionalGroup.id] =
							additionalGroup;

						//now update the user record
						firebaseIO.setUserData(
							user.id,
							'user/additionalGroups',
							additionalGroup.id,
							additionalGroup,
							false,
							processAdditionalGroupAdd,
							null
						);
					} else {
						resultCallback({
							success: false,
							message:
								'There was a problem finding the existing group for this member.',
							messageType: 'error',
						});
					}
				}

				function getPrimaryMember(members, group) {
					//try for original member, group id matches member id
					for (let member in members) {
						if (members[member].userID === group.id) {
							return members[member].account;
						}
					}
					//if no "original" membe anymore, get the first admin
					for (let member in members) {
						if (members[member].admin) {
							return members[member].account;
						}
					}
				}

				function processAdditionalGroupAdd(data) {
					if (data) {
						member.userID = user.id;
						licenseIncreasedAddUser(
							member,
							group,
							members,
							assignUser,
							true
						);
					} else {
						resultCallback(false);
					}
				}

				function licenseIncreasedAddUser(
					member,
					group,
					members,
					assignUser,
					countIsInclusive
				) {
					var hashData = hash.get();
					var activeUserCount = getActiveMemberCount(members);
					var count = countIsInclusive
						? activeUserCount
						: activeUserCount + 1;
					var config = seedcodeCalendar.get('config');

					if (config.manageUserCount || hashData.type === 'trial') {
						// Adjust the user count in billing
						adjustUserLicenseCount(count, function (result) {
							if (result) {
								//Success
								if (member.userID) {
									activateMember(member, group, user);
									return;
								}
								addMemberUser(
									member,
									group,
									members,
									assignUser
								);
							} else {
								//Error
								resultCallback({
									success: false,
									message: '',
									messageType: '',
								});
							}
						});
					} else if (activeUserCount >= hashData.userLimit) {
						// Not enough licenses
						resultCallback({
							success: false,
							message:
								'You do not have enough user licenses to activate this member. Please purchase more user licenses and try again.',
							messageType: 'error',
						});
					} else {
						if (member.userID) {
							// User already exists so it is being added to this group
							activateMember(member, group, user);
							return;
						}
						addMemberUser(member, group, members, assignUser);
					}
				}
			}

			function assignUser(user) {
				var memberOutput = {
					userID: user.id,
				};
				var userGroupOutput = {
					id: groupID,
					memberID: member.id,
					admin: member.admin,
				};
				var output = {
					changePassword: true,
					group: userGroupOutput,
				};
				member.userID = user.id;
				firebaseIO.setUserData(
					user.id,
					'',
					'user',
					output,
					true,
					null,
					null
				);
				firebaseIO.setGroupData(
					'members',
					member.id,
					memberOutput,
					true,
					resultCallback
				);
				sendInviteEmail(user.temporaryPassword);
			}

			/** @type {(temporaryPassword?: string) => void} */
			function sendInviteEmail(temporaryPassword) {
				const config = seedcodeCalendar.get('config');
				const invitedBy = config.accountName;
				const data = {
					journey: '0002',
					firstName: member.firstName,
					lastName: member.lastName,
					email: member.account,
					temporaryPassword: temporaryPassword || '',
					invited: true,
					invitedBy: invitedBy,
					groupName: group.name || '',
					subscriptionStatus: '',
					currentPeriodEndsAt: '',
				};
				// Start add member autopilot journey
				$http({
					method: 'POST',
					url: _CONFIG.DBK_JOURNEY_URL,
					data: data,
				})
					.then(function (response) {
						//Run something after successful post
					})
					.catch(function (error) {
						//Run something on error
					});
			}

			function activateMember(member, group, user) {
				// Just need to add our existing User Id to this member record because this user already exists
				// Used when an existing user is being added to a new group
				firebaseIO.setData(
					'groups/' + group.id + '/members/' + member.id,
					'userID',
					user.id,
					processActivateMember,
					null,
					null
				);
				function processActivateMember(result) {
					resultCallback(user);
					sendInviteEmail();
				}
			}

			function resultCallback(result) {
				var callbackValue;
				if (!result) {
					callbackValue = {
						success: false,
					};
				} else if (result && result.success === false) {
					callbackValue = {
						success: false,
						message: result.message,
						messageType: result.messageType,
					};
				} else {
					callbackValue = {
						success: true,
						data: result,
					};
				}
				callback(callbackValue);
			}
		}

		function addMemberUser(member, group, members, callback) {
			var userName = member.account;
			var password = utilities.generatePassword();

			manageUser.createDayBackUser(
				userName,
				password,
				member.firstName,
				member.lastName,
				group.name,
				'invited',
				true,
				false,
				false,
				false,
				false,
				createUserData
			);
			function createUserData(result) {
				var userProperties;

				if (result?.message) {
					//We had an error creating the user. Do something here
					//ToDo: Figure out a better way to handle this error. This scenario would only happen with a connection drop or something getting messed up with user data.
					utilities.showModal(
						'Error Creating User',
						result.message,
						null,
						null,
						'OK',
						null
					);
				} else if (result) {
					userProperties = {
						id: result.user.uid,
						account: userName,
						name: '',
						firstName: member.firstName,
						lastName: member.lastName,
						email: userName,
						groupID: group.id,
					};
					daybackIO.createUserData(
						userProperties,
						true,
						false,
						true,
						userAddedCallback
					);
				} else {
					//We didn't get any result response - What should we do in this scenario just remove this else statement?
				}

				function userAddedCallback(signUpData) {
					signUpData.user.temporaryPassword = password;
					if (callback) {
						callback(signUpData.user);
					}
				}
			}
		}

		function getActiveMemberCount(members) {
			var activeUserCount = members.filter(function (member) {
				return member.userID && member.active;
			}).length;

			return activeUserCount;
		}

		function removeMemberUser(members, target, member, callback) {
			const config = seedcodeCalendar.get('config');
			const hashData = hash.get();
			const userData = getUserData();
			const groupID = userData.group.id;
			let deleteUser = true;
			const activeMembers = getActiveMemberCount(members);
			const count =
				member.userID && member.active
					? activeMembers - 1
					: activeMembers;

			//need to see if the deleted memberUser has additional groups
			//if so, manage those groups insteaf of deleting the user completely
			firebaseIO.getUserData(member.userID, 'user', processUser);

			function processUser(result) {
				const deletedUser = result;
				if (deletedUser?.additionalGroups) {
					deleteUser = false;
					//if promotion means additionalGroups is empty, then delete additionalGroups
					if (deletedUser.additionalGroups[groupID]) {
						//this is an additional group, remove its reference from the user record
						deletedUser.additionalGroups[groupID] = null;
						if (
							Object.keys(deletedUser.additionalGroups).length ===
							1
						) {
							//if it's the only additional group, then remove additionalGroups object
							deletedUser.additionalGroups = null;
						}
					} else if (deletedUser.group.id === groupID) {
						//this is the primary group, then promote another group to the primary
						const key = Object.keys(
							deletedUser.additionalGroups
						)[0];
						deletedUser.group = deletedUser.additionalGroups[key];
						deletedUser.additionalGroups[key] = null;
						//if additionalGroups is now empty, then remove object
						if (
							Object.keys(deletedUser.additionalGroups).length ===
							1
						) {
							deletedUser.additionalGroups = null;
						}
					}
					firebaseIO.setUserData(
						deletedUser.id,
						'',
						'user',
						deletedUser,
						false,
						processUserUpdate,
						null
					);
				} else {
					processUserUpdate(deletedUser, true);
				}
			}

			function processUserUpdate(result, deleteUser) {
				if (result && result.id) {
					if (
						(config.manageUserCount && member.userID) ||
						(member.userID && hashData.type === 'trial')
					) {
						adjustUserLicenseCount(count, applyMemberDelete);
					} else {
						applyMemberDelete(true);
					}
				} else if (callback) {
					callback(false);
				}
			}

			function applyMemberDelete(result) {
				if (!result) {
					// If there was no result we assume an error from the server so do not continue with the function
					if (callback) {
						callback(false);
					}
					return;
				}

				deleteObject(members, null, target, member, null, true);

				//If this member has been verified we need to remove the group reference from their user profile
				//don't deleete the user if they have additional groups. User record has already been updated
				if (member.userID && deleteUser) {
					$http({
						method: 'POST',
						url: _CONFIG.DBK_API_BASEURL + 'member/delete',
						data: {
							userID: member.userID,
							memberID: member.id,
							groupID: groupID,
						},
					})
						.then(function (response) {
							//Run something after successful post
							onUserEdit(true);
						})
						.catch(function (error) {
							//Run something on error
							callback(false);
						});
				} else {
					firebaseIO.setGroupData(
						'members',
						member.id,
						null,
						false,
						onUserEdit
					);
				}
			}

			function onUserEdit() {
				if (callback) {
					callback(true);
				}
			}
		}

		function addObject(objects, target, dataMap, parentObj) {
			var obj = {};
			var objectData;
			var output = {};
			//Add our defaults
			objectData = transformObjectData(obj, dataMap);

			for (var i = 0; i < objectData.length; i++) {
				output[objectData[i].name] = objectData[i].data;
			}

			if (parentObj) {
				parentObj[output.id] = output;
			}
			//Add new object to object list
			objects.push(output);

			firebaseIO.setGroupData(target, output.id, output, true);

			return output;
		}

		function updateObject(objects, id, path, child, property, value) {
			var output = {};
			var objectPosition;
			// output[id] = id;
			if (Array.isArray(objects)) {
				objectPosition = getObjectPosition(objects, id);
				objects[objectPosition][property] = value;
			} else {
				objects[property] = value;
			}

			output[property] = value;

			firebaseIO.setGroupData(path, child, output, true);
		}

		function updateSourceObject(path, childObject, object) {
			firebaseIO.setGroupData(path, childObject, object, false);
		}

		function deleteObject(
			objects,
			path,
			target,
			obj,
			parentObj,
			localOnly
		) {
			var objectPosition;
			var output = {};
			path = !path ? '' : path;

			if (Array.isArray(objects)) {
				objectPosition = getObjectPosition(objects, obj.id);
				//Remove from our local array
				objects.splice(objectPosition, 1);
			} else {
				delete objects[obj.id];
			}

			if (parentObj) {
				delete parentObj[obj.id];
			}

			//Build object to be deleted and set to null
			output[obj.id] = null;

			if (!localOnly) {
				//Write back to our backend
				firebaseIO.setGroupData(path, target, output, true);
			}

			return objects;
		}

		function transformObjects(objects, dataMap) {
			var output = [];
			for (var obj in objects) {
				if (dataMap) {
					for (var property in dataMap) {
						objects[obj][property] =
							objects[obj][property] === undefined ||
							objects[obj][property] === null ||
							objects[obj][property] === ''
								? dataMap[property].defaultValue
								: objects[obj][property];
					}
				}
				output.push(objects[obj]);
			}
			return output;
		}

		function transformObjectData(data, dataMap) {
			var output = [];
			var setting;

			if (!data) {
				data = {};
			}
			for (var item in dataMap) {
				setting = {};
				setting.name = dataMap[item].setting;
				setting.display = $sce.trustAsHtml(dataMap[item].displayValue);
				setting.placeholder = dataMap[item].placeholder;
				setting.visible = dataMap[item].visible;
				setting.formatas = dataMap[item].format;
				setting.options = dataMap[item].options;
				setting.categoryis = dataMap[item].category;
				setting.helptext = $sce.trustAsHtml(dataMap[item].helptext);
				setting.preventTrim = dataMap[item].preventTrim;
				setting.data =
					data[setting.name] === undefined ||
					data[setting.name] === null ||
					data[setting.name] === ''
						? dataMap[item].defaultValue
						: data[setting.name];
				output.push(setting);
			}
			return output;
		}

		function getConfigMapItem(settingName) {
			var configMap = seedcodeCalendar.calendar.configMap;
			for (var configItem in configMap) {
				if (configMap[configItem].setting === settingName) {
					return configItem;
				}
			}
		}

		function transformSettings() {
			var configMap = seedcodeCalendar.calendar.configMap;
			var output = [];
			var settings = seedcodeCalendar.get('settings');
			var platform = utilities.getDBKPlatform();
			var setting;

			for (var configItem in configMap) {
				setting = {};
				setting.name = configMap[configItem].setting;
				setting.display = $sce.trustAsHtml(
					configMap[configItem].displayValue
				);
				setting.placeholder = $sce.trustAsHtml(
					configMap[configItem].placeholder
				);
				setting.visible = configMap[configItem].platform
					? configMap[configItem].platform === platform &&
						processTransformedSettingItem(
							settings,
							configMap[configItem].visible
						)
					: processTransformedSettingItem(
							settings,
							configMap[configItem].visible
						);
				setting.formatas = configMap[configItem].format;
				setting.options = configMap[configItem].options;
				setting.categoryis = configMap[configItem].category;
				setting.helptext = $sce.trustAsHtml(
					configMap[configItem].helptext
				);
				setting.preventTrim = configMap[configItem].preventTrim;
				setting.data =
					settings[setting.name] === undefined ||
					settings[setting.name] === null ||
					settings[setting.name] === ''
						? configMap[configItem].defaultValue
						: settings[setting.name];
				output.push(setting);
			}
			return output;
		}

		//Return our currently viewed sources
		function transformSources(sourceTypeID, schedules, localSchedules) {
			var sources = seedcodeCalendar.get('sources');
			var sourceTemplate = manageEventSources.getTemplate(sourceTypeID);
			var source;
			var parentSource;
			var scheduleID;
			var output = [];
			var hasParent;

			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTypeID) {
					sources[i].sourceTemplate = sourceTemplate;
					parentSource = sources[i];
					if (
						(schedules && !localSchedules) ||
						(localSchedules && sources[i].localParent && !hasParent)
					) {
						parentSource.isParent = true;
						hasParent = true;
					} else {
						sources[i].editable = true; //We only set an editable property for schedules so just default to true four sources
						applySourceMutations(sources[i]);
					}

					if (sources[i].isParent) {
						output.unshift(sources[i]);
					} else {
						output.push(sources[i]);
					}
				}
			}

			if (schedules && !localSchedules) {
				for (var i = 0; i < schedules.length; i++) {
					scheduleID = utilities.stringToID(schedules[i].id);
					source = {};

					if (parentSource[scheduleID]) {
						for (var property in parentSource[scheduleID]) {
							source[property] =
								parentSource[scheduleID][property];
						}
					}
					source.isSchedule = true;
					source.parentID = parentSource.id;
					source.id = utilities.stringToID(schedules[i].id);
					source.editable = schedules[i].editable;

					if (!source.editable) {
						source.readOnly = true;
					}

					source.schedule = schedules[i];
					source.name = schedules[i].name;
					source.backgroundColor = schedules[i].backgroundColor;
					source.sourceTypeID = sourceTypeID;
					source.sourceTemplate = sourceTemplate;
					source.parentSource = parentSource;
					applySourceMutations(source);

					output.push(source);
				}
			}
			return output;
		}

		//Transform the source data for editing (when editing a source - what displays)
		function transformSourceData(source) {
			var sourceTypeID = source.sourceTypeID;
			var sourceTemplate = manageEventSources.getTemplate(sourceTypeID);
			var sourceSettings = sourceTemplate.settings;
			var sourceFieldMap = sourceTemplate.fieldMap;
			var output = {};
			var fieldMap = {};
			var settingName;

			//Transform settings
			for (var configItem in sourceSettings) {
				settingName = sourceSettings[configItem].setting;
				output[settingName] =
					source[settingName] ||
					sourceSettings[configItem].defaultValue;
			}
			//Transform field map
			//Only set fieldmap if we aren't using default settings
			if (!sourceTemplate.useDefaultFieldMap) {
				for (var configItem in sourceFieldMap) {
					if (sourceFieldMap[configItem].visible) {
						settingName = sourceFieldMap[configItem].setting;
						fieldMap[settingName] =
							source.fieldMap[settingName] ||
							sourceFieldMap[configItem].defaultValue;
					}
				}
			}
			//Append our field map to settings
			output.fieldMap = fieldMap;

			return output;
		}

		function transformSource(source, type) {
			var sourceTypeID = source.sourceTypeID;
			var sourceTemplate = manageEventSources.getTemplate(sourceTypeID);
			var sourceSettings;
			var output = [];
			var setting;
			var labelMap = source.labelMap || {};
			var unusedMap = source.unusedMap || {};
			var allowHTMLMap = source.allowHTMLMap || {};
			var hiddenFieldMap = source.hiddenFieldMap || {};
			var readOnlyFieldMap = source.readOnlyFieldMap || {};
			var allowTextFieldMap = source.allowTextFieldMap || {};
			var relatedValueMap = source.relatedValueMap || {};
			var targetSourceData;

			if (type === 'fieldMap') {
				sourceSettings = sourceTemplate.fieldMap;
				targetSourceData = source.fieldMap || {};
			} else if (type === 'contactData') {
				sourceSettings = sourceTemplate.contactData;
				targetSourceData = source.contactData || {};
			} else if (type === 'projectData') {
				sourceSettings = sourceTemplate.projectData;
				targetSourceData = source.projectData || {};
			} else if (type === 'resourceData') {
				sourceSettings = sourceTemplate.resourceData;
				targetSourceData = source.resourceData || {};
			} else if (type === 'customFields') {
				sourceSettings = customFieldDataMap();
				targetSourceData = source.customFields;
			} else if (type === 'customActions') {
				sourceSettings = customActionDataMap();
				targetSourceData = source.customActions;
			} else {
				sourceSettings = sourceTemplate.settings;
				targetSourceData = source;
			}
			for (var configItem in sourceSettings) {
				if (sourceSettings[configItem].visible) {
					setting = {};
					setting.name = processTransformedSourceItem(
						source,
						sourceSettings[configItem].setting
					);
					setting.defaultLabel = processTransformedSourceItem(
						source,
						sourceSettings[configItem].labelValue
					);
					setting.label = processTransformedSourceItem(
						source,
						labelMap[setting.name] || setting.defaultLabel
					);
					setting.display = $sce.trustAsHtml(
						sourceSettings[configItem].displayValue
					);
					setting.placeholder = $sce.trustAsHtml(
						sourceSettings[configItem].placeholder
					);
					setting.hideAdvanced = processTransformedSourceItem(
						source,
						sourceSettings[configItem].hideAdvanced
					);
					setting.required = processTransformedSourceItem(
						source,
						sourceSettings[configItem].required
					);
					setting.related = processTransformedSourceItem(
						source,
						sourceSettings[configItem].related
					);
					setting.scheduleOnly = processTransformedSourceItem(
						source,
						sourceSettings[configItem].scheduleOnly
					);
					setting.sourceOnly = processTransformedSourceItem(
						source,
						sourceSettings[configItem].sourceOnly
					);
					setting.unused = processTransformedSourceItem(
						source,
						unusedMap[setting.name]
					);
					setting.allowHTML = processTransformedSourceItem(
						source,
						allowHTMLMap[setting.name]
					);
					setting.hiddenField = processTransformedSourceItem(
						source,
						hiddenFieldMap[setting.name]
					);
					setting.readOnlyField = processTransformedSourceItem(
						source,
						readOnlyFieldMap[setting.name]
					);
					setting.allowTextField = processTransformedSourceItem(
						source,
						allowTextFieldMap[setting.name]
					);
					setting.relatedValue = processTransformedSourceItem(
						source,
						relatedValueMap[setting.name]
					);
					setting.helptext = $sce.trustAsHtml(
						sourceSettings[configItem].helptext
					);
					setting.preventTrim = processTransformedSourceItem(
						source,
						sourceSettings[configItem].preventTrim
					);
					setting.formatas = processTransformedSourceItem(
						source,
						sourceSettings[configItem].format
					);
					setting.options = processTransformedSourceItem(
						source,
						sourceSettings[configItem].options
					);
					setting.uiOnly = processTransformedSourceItem(
						source,
						sourceSettings[configItem].uiOnly
					);
					setting.data =
						targetSourceData[setting.name] === undefined ||
						targetSourceData[setting.name] === null ||
						targetSourceData[setting.name] === ''
							? sourceSettings[configItem].defaultValue
							: targetSourceData[setting.name];
					//Apply global setting for schedule based sources
					if (targetSourceData.parentSource) {
						if (
							setting.name !== 'name' &&
							!sourceTemplate.settings[setting.name]
								.scheduleOnly &&
							targetSourceData.parentSource[setting.name] !==
								'disable-global'
						) {
							setting.data =
								targetSourceData.parentSource[setting.name];
						}
					}
					output.push(setting);
				}
			}

			return output;
		}

		function processTransformedSettingItem(settings, setting) {
			return typeof setting === 'function' ? setting(settings) : setting;
		}

		function processTransformedSourceItem(source, setting) {
			return typeof setting === 'function' ? setting(source) : setting;
		}

		function applySourceMutations(source) {
			var config = seedcodeCalendar.get('config');
			var settings = seedcodeCalendar.get('settings');
			var resources = seedcodeCalendar.get('resources');
			var scheduleKey = source.schedule ? source.schedule.id : source.id;
			var enabled = true; //Default the schedule to show unless otherwise specified
			//Set schedule identifier
			source.id = !source.id ? sourceTemplate.id + '-' + i : source.id;

			// Init hideMap
			if (!source.hideMap) {
				source.hideMap = {};
			}

			//Set primary calendar
			source.isPrimary = config.primaryCalendar === scheduleKey;
			source.primaryCalendarOverride =
				config.primaryCalendarOverride === scheduleKey;

			//Set default resources
			if (
				settings.defaultResources &&
				settings.defaultResources[source.id]
			) {
				for (
					var resourceCount = 0;
					resourceCount < resources.length;
					resourceCount++
				) {
					if (
						resources[resourceCount].id ===
						settings.defaultResources[source.id]
					) {
						//Set the defauilt resource property to the selected resource id
						source.defaultResource = resources[resourceCount].id;
					}
				}
			}

			// Disable unscheduled field mapping if unscheduled isn't allowed
			if (!source.allowUnscheduled) {
				source.hideMap.unscheduled = true;
			}
		}

		function getFieldMapItem(fieldMap, name) {
			// Get a signle object from an array of objects based on a matching name
			// This is useful for getting a specific element from the source fieldmap
			if (!fieldMap || !fieldMap.length || !name) {
				return;
			}

			for (var i = 0; i < fieldMap.length; i++) {
				if (fieldMap[i] === name) {
					return fieldMap[i];
				}
			}
		}
	}
})();
